<template>

    <!-- NOTE: We manually map data to vue components here -->

    <template v-for="(sceneComponent, i) in sceneComponents" :key="i">

      <HotspotButton v-if="sceneComponent.nsAlias === 'hotspotButton'" :key="i"
        :x="sceneComponent.positionX"
        :y="sceneComponent.positionY"
        :title="sceneComponent.title"
        :target-id="sceneComponent.sceneContentReference"
        :external-url="sceneComponent.externalURL"
        :has-transition="true"
        :has-pulse="true"
        :direction="sceneComponent.direction"
        :transition-delay-ms="hotspotDelaysByIndex[i]"
        :pulse-interval-ms="hotspotDelaysByIndex[i] + pulseIntervalMs"
        :globalColor="sceneComponent.globalColor"
      />

      <HotspotInfo v-if="sceneComponent.nsAlias === 'hotspotInfo'" :key="i"
        :x="sceneComponent.xCoordinate"
        :y="sceneComponent.yCoordinate"
        :title="sceneComponent.title"
        :globalColor="sceneComponent.globalColor"
        :description="sceneComponent.description"
      />

      <OverlayTitle v-if="sceneComponent.nsAlias === 'overlayTitleContent'" :key="i"
        :title="sceneComponent.title"
        :subtitle="sceneComponent.subtitle"
        :textColor="sceneComponent.textColor"
        :topRightImage="sceneComponent.topRightImage"
        :bottomRightImage="sceneComponent.bottomRightImage"
        :showBackButton="sceneComponent.backButton ? sceneComponent.backButton : false"
        :logoImages="sceneComponent.logoImages"
        :globalColor="sceneComponent.globalColor"
      />

      <TurntableDynamic v-if="sceneComponent.nsAlias === 'turntable'" :key="i"
        :title="sceneComponent.title"
        :themesHeading="sceneComponent.themesHeading"
        :hotspotSlices="sceneComponent.hotspotSlices"
        :globalColor="sceneComponent.globalColor"
      />
      
      <Turntable v-if="sceneComponent.nsAlias === 'turntableOld'" :key="i"
        :title="sceneComponent.title"
        :hotspotSlices="sceneComponent.hotspotSlices"
      />

      <TurntableSelector v-if="sceneComponent.nsAlias === 'turntableSelector'" :key="i"
        :tabs="sceneComponent.tabs"
      />

      <ContentPage v-if="sceneComponent.nsAlias === 'contentPage'" :key="i"
        :title="sceneComponent.title"
        :subtitle="sceneComponent.subtitle"
        :tabs="sceneComponent.contentTabs"
        :backgroundLine="sceneComponent.backgroundLine"
      />

    </template>

</template>

<script>
import HotspotButton from '@/components/scene-components/HotspotButton';
import HotspotInfo from '@/components/scene-components/HotspotInfo';
import Turntable from '@/components/scene-components/Turntable';
import TurntableDynamic from '@/components/scene-components/TurntableDynamic';
import TurntableSelector from '@/components/scene-components/TurntableSelector';
import OverlayTitle from '@/components/scene-components/OverlayTitle';
import ContentPage from '@/components/scene-components/ContentPage';

export default {
  name: 'SceneComponentRenderer',
  components: {
    HotspotButton, 
    Turntable,
    TurntableDynamic,
    TurntableSelector,
    OverlayTitle,
    ContentPage,
    HotspotInfo
  },
  props: {
      sceneComponents: Array
  },
  data()
  {
    return {
      initialDelayMs: 400,
      delayOffsetMs: 200,
      pulseIntervalMs: 15000,
      hotspotDelaysByIndex: []
    }
  },
  created()
  {
    // NOTE: Populate hotspotIndexes array with data to use to calculate delay time...
    var i = 0;
    this.sceneComponents.forEach((component, j)=>{
      if (component.nsAlias === 'hotspotButton')
      {
        this.hotspotDelaysByIndex[j] = this.initialDelayMs + (i * this.delayOffsetMs);
        i++;
      }
    });
  }
}
</script>

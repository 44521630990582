<template>
  <div class="turntableSelector">
    <div v-for="tab in tabs" :key="tab" :id="tab.title" class="turntableSelector-tabContainer" :style="`
      background: linear-gradient(0deg,` + tab.backgroundGradient1+`2b` + ` 0%, ` + tab.backgroundGradient2+`0d` + ` 40%, ` + tab.backgroundGradient3 + ` 100%);
    `">
      <img v-if="tab.styleImage" class="backgroundImage" :src="getUmbracoMediaItemSrc(tab.styleImage)"/>
      <div class="turntableSelector-tabContent"   @click="onClick($event, tab)">
        <p>{{tab.subtitle}}</p>
        <h2>{{tab.title}}</h2>
        <img v-if="tab.selectionImage" class="selectionImage" :src="getUmbracoMediaItemSrc(tab.selectionImage)"/>
      </div>
    </div>
  </div>
</template>

<script>
import { getUmbracoMediaItemSrc } from '@/helpers/assetHelper';
import {goToPathByID} from '@/helpers/navigationHelper';


export default {
  name: 'TurntableSelector',
  props:
  {
      title: String,
      tabs: Array
  },
  computed:
  {
  },
  methods:
  {
    onClick(event, tab)
    {
      if(tab.sceneReference){

        let TabContainer = event.target.parentNode.parentNode;
        let SelectorContainer = event.target.parentNode.parentNode.parentNode;
        console.log(SelectorContainer);
        for(let x = 0; x < SelectorContainer.children.length; x++){
            if(TabContainer.id != SelectorContainer.children[x].id){
              console.log(SelectorContainer.children[x]);
              SelectorContainer.children[x].innerHTML = "";
              SelectorContainer.children[x].style.width = "0%";
            }
        }
        TabContainer.style.width = "100%";
        let BackgroundImg = event.target.parentNode.parentNode.querySelector(".backgroundImage");
        BackgroundImg.style.position = "absolute";
        BackgroundImg.style.top = "0";
        BackgroundImg.style.right = "-28px";

        let TabContent = event.target.parentNode;
        // //TabContent
        // //top: 0;
        TabContent.style.top = "-47.8%";
        TabContent.style.left = "120px";
        TabContent.style.transform = "scale(1.1)";

        let transitionP = event.target.parentNode.parentNode.querySelector("p");
        let transitionH = event.target.parentNode.parentNode.querySelector("h2");
        // //P & H2
        // //display: none;
        transitionP.style.opacity = "0";
        transitionH.style.opacity = "0";

        setTimeout(() => {
          goToPathByID(tab.sceneReference);
        }, 1001);
      }
    },
    getUmbracoMediaItemSrc(mediaItem)
    {
        return getUmbracoMediaItemSrc(mediaItem);
    }
  },
  mounted() {
    document.cookie = "degreePosition=";
  }
}
</script>

<template>

  <Scene
    :style="'transform-origin: '+transitionZoomTransformOrigin"
    :class="[{'scene--transition-effect-fade': (transitionType === 'fade')}, {'scene--transition-effect-zoom': (transitionType === 'zoomAndFade')}]"
    v-if="sceneContent.sceneData"
    :key="sceneContent.sceneData.id"
    :background-image="sceneContent.sceneData.data.backgroundImage"
    :background-video="sceneContent.sceneData.data.backgroundVideo">

    <!-- NOTE: Child views are rendered here if any -->
    <router-view></router-view>

    <SceneComponentRenderer v-if="sceneContent.sceneData.sceneComponents" :sceneComponents="sceneContent.sceneData.sceneComponents"/>
  </Scene>

  <ContentComponentRenderer v-if="sceneContent.contentComponentData" :contentComponentData="sceneContent.contentComponentData"/>

</template>

<script>
// @ is an alias to /src
import Scene from '@/components/Scene';
import SceneComponentRenderer from '@/components/SceneComponentRenderer';
import ContentComponentRenderer from '@/components/ContentComponentRenderer';
import {getSceneContentByPath} from '@/helpers/navigationHelper';

export default {
  name: 'SceneView',
  components: {
    Scene: Scene,
    SceneComponentRenderer: SceneComponentRenderer,
    ContentComponentRenderer: ContentComponentRenderer,
  },
  data()
  {
    return {
      sceneContent: {},
      transitionType: null,
      transitionZoomTransformOrigin: '50% 50%',
    }
  },
  beforeRouteUpdate(to, from, next)
  {
    // NOTE: Get scene data on update
    this.sceneContent = getSceneContentByPath(to.path);

    // NOTE: Cancel transition if next route is a content component, by setting the default.
    if (this.sceneContent.contentComponentData)
    {
      this.$store.commit('setSceneTransitionEffect', {type: 'fade'});
    }

    next();
  },
  created()
  {
    // NOTE: Get initial scene data
    this.unsubscribe = this.$store.subscribe((mutation, state) =>
    {
      // NOTE: React to the manifest being ready for use
      if (mutation.type === 'attatchSortedManifestData')
      {
        this.sceneContent = getSceneContentByPath(this.$route.path);
      }

      // NOTE: React to transitions
      else if (mutation.type === 'setSceneTransitionEffect')
      {
        this.transitionType = state.sceneTransitionEffect.type;
        if (state.sceneTransitionEffect.type === 'zoomAndFade')
        {
          this.transitionZoomTransformOrigin = state.sceneTransitionEffect.transformOrigin;
        }
      }
    })
  },
  methods: {
    afterTransition()
    {
      // NOTE: Reset transition effect
      this.$store.commit('setSceneTransitionEffect', {type: 'fade'});
    }
  },
  unmounted()
  {
    this.unsubscribe();
  }
}
</script>
<template>
    <div class="scene">

        <PreloadedImage v-if="backgroundImage" class="scene__background-image" :src="mediaUrl(backgroundImage)"/>
        <div v-if="backgroundVideo" class="scene__gradientBackground"></div>
        <video 
          v-if="backgroundVideo"
          :src="mediaUrl(backgroundVideo)"
          class="scene__background-video"
          muted
          autoplay
          loop
          playsinline
          webkit-playsinline
          type="video/mp4"
          @contextmenu.prevent=""></video>
          
          <slot></slot>
    </div>
</template>


<script>
import { getUmbracoMediaItemSrc } from '@/helpers/assetHelper';
import PreloadedImage from '@/components/PreloadedImage'

export default {
  name: 'Scene',
  components: {
    PreloadedImage
  },
  props: {
      backgroundImage: Object,
      backgroundVideo: Object
  },
  methods:
  {
    mediaUrl(image)
    {
      return getUmbracoMediaItemSrc(image);
    }
  }
}
</script>

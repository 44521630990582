<template>
	<div class="turntable"
	:class="[{'turntable--is-moving': isGrabbing}, {'turntable--is-meaningful-drag': meaningFullDragEventEmitted}]">

        <!--
        <div class="turntable__debug-visualisation">
            <div class="turntable__debug-visualisation-timeline"></div>
            <div class="turntable__debug-visualisation-cursor" :style="'left:'+turntableRotation+'px'"></div>
            <div v-for="snapPoint,i in snapPoints" class="turntable__debug-visualisation-snap-point" :class="{'turntable__debug-visualisation-snap-point--active': activeSnapPosition === snapPoint.pos}" :style="'left:'+snapPoint.pos+'px'" :key="i"></div>
        </div>
        -->

        <div class="turntable__main-title" v-html="title"></div>

        <div class="turntable__sog-logo"></div>

        <div class="turntable__overlay"></div>
        <div class="turntable__overlay-line"></div>

        <canvas 
            ref="turntableCanvas"
            width="1920"
            height="1080"
            class="turntable__canvas"
            @wheel='handleWheel'
            @pointerdown='handleMouseDown'
            @pointerup='handleMouseUp'
            @pointermove='handleMouseMove'></canvas>

        <div v-show="playVideo" class="turntable__videos">
            <video ref="resources" style="visibility: hidden" loop muted src="turntable-videos/SoG - MASTER - 1-0.m4v" data-length="19.04"></video>
            <video ref="supply" style="visibility: hidden" loop muted src="turntable-videos/SoG - MASTER - 2-0.m4v" data-length="10.52"></video>
            <video ref="consumption" style="visibility: hidden" loop muted src="turntable-videos/SoG - MASTER - 3-0.m4v" data-length="11.04"></video>
            <video ref="waste" style="visibility: hidden" loop muted src="turntable-videos/SoG - MASTER - 4-0.m4v" data-length="11.44"></video>
            <video ref="climate-adaption" style="visibility: hidden" loop muted src="turntable-videos/SoG - Master - 5-0.m4v" data-length="20"></video>
        </div>

        <!--<HotspotsIn3DSpace :enabled="true" :hotspots="hotspots" :rotation="-Math.floor(turntableRotation)" @goToPosition="goToPosition"/>-->
        <HotspotsIn3DSpace :enabled="true" :hotspots="hotspots" :rotation="-turntableRotation" @goToPosition="goToPosition"/>

	</div>
</template>

<script>
import pad from '@/helpers/pad';
import config from '@/config.js';
import {getPreloadedImage} from '@/helpers/assetHelper';
import HotspotsIn3DSpace from '@/components/HotspotsIn3DSpace';
import eventBus from '@/helpers/eventBus';

export default {
	name: 'turntable',
	props: {
        title: String,
        hotspotSlices: Object
    },
    components: {HotspotsIn3DSpace},
	data()
	{
		return {
            // isLoading: false,
			// imagesLoaded: false,
			imageData: [],
            loadingPercentage: 0,
            usesWebP: true,
            numberOfFrames: 360,
            indexOfFirstFrame: 0,

            demoModeEnabled: true,
            demoModeCurrentSnappointTimer: 0,
            preventDemoMode: false,
            idleTimer: 0,
            playVideo: false,
            videoBeingPlayed: '',
            secondsBeforeMovingOnFromVideoPosition: 20, // NOTE: This value is changed when a video starts...
            secondsIdleBeforeEnteringDemoMode: 20, // NOTE: The same as above now I guess...
            lastActiveSnapPosition: null,
            activeSnapPosition: null,

			// NOTE: Rotation/Drag state
			turntableRotation: 72,
			dragScale: 0.085,
			isGrabbing: false,
            previousMousePosition: {x: 0, y: 0},
            totalPixelsDragged: 0,
            meaningFullDragEventEmitted: false,
            velocityX: 0,
            timeLastFrame: 0,
            dragLastFiveFrames: [
                {time: 0, posX: 0},
                {time: 0, posX: 0},
                {time: 0, posX: 0},
                {time: 0, posX: 0},
                {time: 0, posX: 0}
            ],
		}
	},
    computed:
    {
        snapPoints()
        {
            var result = [];

            this.hotspotSlices.forEach((hotspotSlice)=>{
               result.push({pos: hotspotSlice.positionDegrees});
            });

            return result;
        },

        hotspots()
        {
            var result = [];

            this.hotspotSlices.forEach((hotspotSlice)=>{
                const donutRadius = 35000;
                const distanceToEdge = 250;
                result.push({
                    pos: {
                        x:(donutRadius-distanceToEdge) * Math.sin(Math.PI * 2 * hotspotSlice.positionDegrees / 360),
                        y:0.0,
                        z:(donutRadius-distanceToEdge) * Math.cos(Math.PI * 2 * hotspotSlice.positionDegrees / 360)
                    },
                    title: hotspotSlice.title,
                    contentReference: hotspotSlice.contentReference,
                    positionDegrees: hotspotSlice.positionDegrees
                });
            });
            
            return result;
        }
    },
	mounted()
	{
        this.ctx = this.$refs.turntableCanvas.getContext('2d');

        for (var i=this.indexOfFirstFrame; i < this.numberOfFrames + this.indexOfFirstFrame; i++)
        {
            (()=>
            {
                var filePathName = config.tempMediaPath+'turntable/SoG - MASTER - 360_'+pad(i+this.indexOfFirstFrame, 5)+'.webp';
                this.imageData[i] = getPreloadedImage(filePathName);
            })();
        }

        this.imagesLoaded = true;

        this.updateLoop();
	},
    created()
    {
        this.events = {
            onDisableDemoMode: ()=>{
                this.preventDemoMode = true;
            },
            onEnableDemoMode: ()=>{
                this.preventDemoMode = false;
            },
        }

        eventBus.on('turntable.disableDemoMode', this.events.onDisableDemoMode);
        eventBus.on('turntable.enableDemoMode', this.events.onEnableDemoMode);
    },
    unmounted()
    {
        eventBus.off('turntable.disableDemoMode', this.events.onDisableDemoMode);
        eventBus.off('turntable.enableDemoMode', this.events.onEnableDemoMode);
    },
	methods:
	{
        goToPosition(positionDegrees)
        {
            this.stopDemoMode();
            this.stopSnapPositionVideo();
            this.demoModeEnabled = false;
            this.activeSnapPosition = positionDegrees;
        },
		handleWheel(event)
        {
            this.velocityX += event.deltaY / 2; // TODO: No magic numbers

            this.stopSnapPositionVideo();

            this.activeSnapPosition = null;

            this.stopDemoMode();

            this.showDragHint = false;
        },
        updateLoop(timeThisFrame)
        {
            var deltaTime = (timeThisFrame - this.timeLastFrame) / 1000;
            if (isNaN(deltaTime)) {deltaTime = 0;}

            if (!this.isGrabbing)
            {
                // NOTE: Start demomode if enough time has passed
                if (!this.demoModeEnabled && this.idleTimerStart + (this.secondsIdleBeforeEnteringDemoMode*1000) < Date.now())
                {
                    this.startDemoMode();
                }

                // NOTE: Demo mode
                if (this.demoModeEnabled)
                {
                    if (!this.preventDemoMode)
                    {
                        // NOTE: If "parked" at snapposition
                        if (this.activeSnapPosition !== null)
                        {
                            if (this.demoModeCurrentSnappointTimer + (this.secondsBeforeMovingOnFromVideoPosition*1000) < Date.now())
                            {
                                // NOTE: Moving on in demo mode
                                this.activeSnapPosition = null;
                                this.stopSnapPositionVideo();
                            }
                        }
                        else
                        {
                            // NOTE: Automatically rotate

                            this.turntableRotation += deltaTime * 30; // TODO: No magic numbers
                            this.turntableRotation = this.wrapRotation(this.turntableRotation);
                        }


                        // NOTE: Handle stopping at snap points, playing their content and continuing.
                        var snapPositionInRange = this.getSnapPositionInSnapRange(15) // TODO: No magic numbers
                        if (snapPositionInRange != null)
                        {
                            if (snapPositionInRange != this.lastActiveSnapPosition)
                            {
                                // NOTE: Detect if rotation is past the snap point
                                let snapPointOvershot = false;
                                let distanceLessThan180 = (Math.abs(snapPositionInRange - this.turntableRotation) < 180);
                                if (snapPositionInRange < this.turntableRotation && distanceLessThan180)
                                {
                                    snapPointOvershot = true
                                }

                                if (snapPointOvershot)
                                {
                                    this.activeSnapPosition = snapPositionInRange;
                                    this.lastActiveSnapPosition = snapPositionInRange;
                                    this.demoModeCurrentSnappointTimer = Date.now();

                                    // NOTE: Snap to it
                                    this.turntableRotation = snapPositionInRange;
                                    this.turntableRotation = this.wrapRotation(this.turntableRotation);

                                    this.startSnapPositionVideo(snapPositionInRange);
                                }
                            }
                        }
                    }
                }
                else
                {
                    if (deltaTime > 0)
                    {
                        // NOTE: Check if we should snap
                        if (this.activeSnapPosition == null)
                        {
                            // NOTE: If not grabbing and below a certain velocity.
                            const snapThresholdVelocity = 60;
                            const distanceToSnap = 100;

                            if (Math.abs(this.velocityX) < snapThresholdVelocity)
                            {
                                this.activeSnapPosition = this.getSnapPositionInSnapRange(distanceToSnap);
                                this.lastActiveSnapPosition = this.activeSnapPosition;
                            }
                            else
                            {
                                this.activeSnapPosition = null;
                            }
                        }

                        // NOTE: Animate to snapposition
                        if (this.activeSnapPosition != null)
                        {
                            // NOTE: Handle wrapping around (so we don't go the long way around by mistake)
                            let offset = this.activeSnapPosition - this.turntableRotation;
                            let offsetWrappedRight = this.activeSnapPosition - this.turntableRotation + 360;
                            if (Math.abs(offsetWrappedRight) < Math.abs(offset)) {offset = offsetWrappedRight}
                            let offsetWrappedLeft = (this.activeSnapPosition - 360) - this.turntableRotation;
                            if (Math.abs(offsetWrappedLeft) < Math.abs(offset)) {offset = offsetWrappedLeft}

                            if (offset > 2) // TODO: No magic numbers
                            {
                                this.turntableRotation += deltaTime * 60; // TODO: NO MAGIC NUMBERS
                            }
                            else if (offset < -2) // TODO: No magic numbers
                            {
                                this.turntableRotation -= deltaTime * 60; // TODO: NO MAGIC NUMBERS
                            }
                            else
                            {
                                this.turntableRotation = this.activeSnapPosition;

                                this.startSnapPositionVideo(this.activeSnapPosition);
                            }


                            this.turntableRotation = this.wrapRotation(this.turntableRotation);

                        }
                        // NOTE: Move by current velocity
                        else
                        {
                            if (!isFinite(this.velocityX)) this.velocityX = 0;

                            var newOffsetX = this.velocityX * deltaTime;
                            this.turntableRotation += newOffsetX;
                            this.turntableRotation = this.wrapRotation(this.turntableRotation);

                            // NOTE: Dampen and clamp velocity
                            const maxVelocity = 500;
                            const haltVelocity = 12;

                            // NOTE: Set max velocity...
                            if (this.velocityX > maxVelocity)
                                this.velocityX = maxVelocity;
                            else if (this.velocityX < -maxVelocity)
                                this.velocityX = -maxVelocity;

                            // NOTE: Min velocity
                            if (this.velocityX > 0 && this.velocityX < haltVelocity)
                                this.velocityX = 0;
                            else if (this.velocityX < 0 && this.velocityX > -haltVelocity)
                                this.velocityX = 0;
                            else
                            {
                                // NOTE: Dampen velocity...
                                var velocityDampened = this.velocityX * Math.pow(0.08, deltaTime); // TODO: No magic numbers

                                if (isFinite(velocityDampened))
                                {
                                    this.velocityX = velocityDampened;
                                }
                            }
                        }

                    }
                }
            }

            // NOTE: Render
            if (this.imagesLoaded)
            {
                this.ctx.clearRect(0, 0, this.$refs.turntableCanvas.width, this.$refs.turntableCanvas.height);

                var frameIndex = Math.floor(this.turntableRotation * (this.numberOfFrames / 360));

                if (frameIndex >= 0 && frameIndex <= this.numberOfFrames + this.indexOfFirstFrame)
                {
                    try
                    {
                        this.ctx.drawImage(this.imageData[frameIndex], 0, 0);
                    }
                    catch
                    {
                        // NOTE: Ignore errors
                        console.error('Could not find image for frame: ' + frameIndex, this.imageData[frameIndex]);
                    }
                }
            }

            this.timeLastFrame = timeThisFrame;

            window.requestAnimationFrame(this.updateLoop);
        },

        appClientScaleDifference()
        {
            var appElement = document.querySelector('.aspect-ratio');
            return (parseFloat(appElement.getAttribute('data-scale')));
        },

        handleMouseDown(event)
        {
            this.isGrabbing = true;

            this.previousMousePosition = {
                x: Math.floor(event.clientX / this.appClientScaleDifference()),
                y: Math.floor(event.clientY / this.appClientScaleDifference())
            };

            // NOTE: This allows us to detect mouseup and move outside the browserwindow
            if (event.target.setPointerCapture)
            {
                event.target.setPointerCapture(event.pointerId);
            }
        },

        handleMouseMove(event)
        {
            event.preventDefault();

            var timeOfEvent = performance.now();

            if (this.isGrabbing)
            {
                // NOTE: Get mouseposition in scene space
                var mousePosition = {
                    x: Math.floor(event.clientX / this.appClientScaleDifference()),
                    y: Math.floor(event.clientY / this.appClientScaleDifference())
                };

                var posX = event.clientX / this.appClientScaleDifference();

                // NOTE: Grab offset history from last frames. And shift saved values
                this.dragLastFiveFrames[4].time = this.dragLastFiveFrames[3].time;
                this.dragLastFiveFrames[4].posX = this.dragLastFiveFrames[3].posX;
                
                this.dragLastFiveFrames[3].time = this.dragLastFiveFrames[2].time;
                this.dragLastFiveFrames[3].posX = this.dragLastFiveFrames[2].posX;
                
                this.dragLastFiveFrames[2].time = this.dragLastFiveFrames[1].time;
                this.dragLastFiveFrames[2].posX = this.dragLastFiveFrames[1].posX;
                
                this.dragLastFiveFrames[1].time = this.dragLastFiveFrames[0].time;
                this.dragLastFiveFrames[1].posX = this.dragLastFiveFrames[0].posX;
                
                this.dragLastFiveFrames[0].time = timeOfEvent;
                this.dragLastFiveFrames[0].posX = posX;
        
                var differenceX = mousePosition.x - this.previousMousePosition.x;

				// NOTE: Pixels dragged do not equal turntable degrees/frames, so lets turn down the numbers...
				differenceX = differenceX * this.dragScale;

                this.totalPixelsDragged += Math.abs(differenceX);

                this.previousMousePosition = mousePosition;

                this.activeSnapPosition = null;
                this.stopSnapPositionVideo();

                this.turntableRotation = this.wrapRotation(this.turntableRotation - differenceX);

                if (this.totalPixelsDragged > 30 && !this.meaningFullDragEventEmitted) // TODO: No magic numbers
                {
                    // NOTE: We will use this elsewhere to disable interactions, in order to prevent openings things when just trying to drag
                    // app.radio.$emit('expo-meaningful-drag-started');
                    this.meaningFullDragEventEmitted = true;
                    this.showDragHint = false;
                }

            }
        },
        
        handleMouseUp(event)
        {
            this.isGrabbing = false;
            this.totalPixelsDragged = 0;
            this.meaningFullDragEventEmitted = false;

            this.stopDemoMode();

            var dragDuration = this.dragLastFiveFrames[0].time - this.dragLastFiveFrames[4].time;
            var dragDistanceX = this.dragLastFiveFrames[0].posX - this.dragLastFiveFrames[4].posX;

            // NOTE(Mølby): Prevent velocity if some time has passed since last dragframe
            var currentTime = performance.now();

            const timeToAllowVelocityMs = 75;
            var timeOfRelease = this.dragLastFiveFrames[0].time + timeToAllowVelocityMs;

            var dragWasStoppedBeforeRelease = (timeOfRelease < currentTime);
            if (dragWasStoppedBeforeRelease)
            {
                this.velocityX = 0;
                this.velocityY = 0;
            }
            else
            {
                var velocityX = -dragDistanceX / (dragDuration / 1000);
                this.velocityX = velocityX * this.dragScale;
            }

            // NOTE(Mølby) Clear drag data
            this.dragLastFiveFrames = [
                {time: 0, posX: 0},
                {time: 0, posX: 0},
                {time: 0, posX: 0},
                {time: 0, posX: 0},
                {time: 0, posX: 0}
            ];

            // NOTE(Mølby): This allows us to detect mouseup and move outside the browserwindow
            if (event.target.releasePointerCapture)
            {
                event.target.releasePointerCapture(event.pointerId);
            }
        },

        wrapRotation(turntableRotation)
        {
            var newturntableRotation = turntableRotation;

            if (newturntableRotation < 0)
            {
                // NOTE: Wrap around this way: >
                newturntableRotation = 359 + newturntableRotation;
            }
			else if (newturntableRotation > 359)
			{
				newturntableRotation = newturntableRotation - 359;
			}

			// NOTE: Final check
			if (newturntableRotation < 0 || newturntableRotation > 359) 
			{
				newturntableRotation = this.wrapRotation(newturntableRotation);
			}

            return newturntableRotation;
        },

        startDemoMode()
        {
            this.activeSnapPosition = null;

            this.stopSnapPositionVideo();
            this.demoModeEnabled = true;

            // NOTE: Reset demo timer
            this.idleTimerStart = Date.now();
        },

        stopDemoMode()
        {
            this.demoModeEnabled = false;

            // NOTE: Reset demo timer
            this.idleTimerStart = Date.now();
        },

        startSnapPositionVideo(snapPosition)
        {
            if (!this.playVideo)
            {
                var videoId = null;

                this.hotspotSlices.forEach((hotspotSlice)=>{
                    if(hotspotSlice.positionDegrees === snapPosition)
                    {
                        videoId = hotspotSlice.videoID;
                    }
                });

                if (videoId && this.$refs[videoId])
                {
                    var videoElement = this.$refs[videoId];
                    videoElement.style.visibility = 'visible';

                    this.secondsBeforeMovingOnFromVideoPosition = parseFloat(videoElement.getAttribute('data-length'));
                    this.secondsIdleBeforeEnteringDemoMode = this.secondsBeforeMovingOnFromVideoPosition;

                    videoElement.play().catch(()=>{
                        // NOTE: Ignore errors
                    });
                  
                }

                this.playVideo = true;
                
            }
        },

        stopSnapPositionVideo()
        {
            if (this.playVideo)
            {
                this.playVideo = false;

                this.hotspotSlices.forEach((hotspotSlice)=>{
                    {
                        var videoId = hotspotSlice.videoID;
                        var videoElement = this.$refs[videoId];
                        videoElement.style.visibility = 'hidden';
                        videoElement.pause();
                        videoElement.currentTime = 0;
                    }
                });
            }
        },

        getSnapPositionInSnapRange(distanceToSnap)
        {
            // NOTE: Check for nearby snappoint
            let nextActiveSnapPosition = null;
            let nextActiveSnapPositionDistance = 9999;

            this.snapPoints.forEach((snapPoint)=>{

                let distance = Math.abs(snapPoint.pos - this.turntableRotation);

                // NOTE: Handle wrapping...
                let distanceWrappedRight = Math.abs(snapPoint.pos - this.turntableRotation + 360);
                if (distanceWrappedRight < distance) {distance = distanceWrappedRight;}
                let distanceWrappedLeft = Math.abs((snapPoint.pos - 360) - this.turntableRotation);
                if (distanceWrappedLeft < distance) {distance = distanceWrappedLeft;}

                if (distance < nextActiveSnapPositionDistance && distance < distanceToSnap)
                {
                    nextActiveSnapPositionDistance = distance;
                    nextActiveSnapPosition = snapPoint.pos;
                }
            });
            
            return nextActiveSnapPosition;
        }
	}
}
</script>
<template>
    <transition name="fade" mode="out-in">
        <div v-if="show" class="exhibitors-popup">

            <div class="exhibitors-popup__close-btn" @click="close"></div>
            
            <div class="exhibitors-popup__title">{{title}}</div>

            <div class="exhibitors-popup__text-box">
                <div class="exhibitors-popup__text" v-html="text"></div>
                <div class="exhibitors-popup__cta-text">{{callToActionText}}</div>
            </div>

            <div class="exhibitors-popup__grid">

                <div class="exhibitors-popup__exhibitor-btn"
                @click="selectedExhibitorId = exhibitor.id; showSelectedExhibitor = true"
                v-for="exhibitor in exhibitorsWithData" :key="exhibitor.id">
                    <img v-if="exhibitor.data.logo" :src="getUmbracoMediaItemSrc(exhibitor.data.logo)" class="exhibitors-popup__exhibitor-btn-logo"/>
                </div>
        
            </div>

            <div class="exhibitors-popup__exhibitor-popup exhibitor-popup" :class="{'exhibitor-popup--is-open': showSelectedExhibitor}">

                <div class="exhibitors-popup__close-btn" @click="showSelectedExhibitor = false"></div>

                <template v-if="selectedExhibitor">
                    
                    <div class="exhibitor-popup__logo">
                        <img v-if="selectedExhibitor.data.logo" :src="getUmbracoMediaItemSrc(selectedExhibitor.data.logo)"/>
                    </div>

                    <div class="exhibitor-popup__text-content">
                    
                        <div class="exhibitor-popup__heading" v-html="selectedExhibitor.data.heading"></div>

                        <div class="exhibitor-popup__text" v-html="selectedExhibitor.data.text"></div>

                        <div class="exhibitor-popup__find-us">
                            <div class="" v-html="selectedExhibitor.data.findUsHereText"></div>
                            <img v-if="selectedExhibitor.data.findUsHereImage" :src="getUmbracoMediaItemSrc(selectedExhibitor.data.findUsHereImage)"/>
                        </div>
                    
                    </div>

                    <div class="exhibitor-popup__image">
                        <img v-if="selectedExhibitor.data.image" :src="getUmbracoMediaItemSrc(selectedExhibitor.data.image)"/>
                    </div>

                </template>

            </div>

        </div>
    </transition>
</template>

<script>
import { getUmbracoMediaItemSrc } from '@/helpers/assetHelper';
import eventBus from '@/helpers/eventBus';

export default {
    name: 'ExhibitorsPopup',
    props: {
        callToActionText: String,
        title: String,
        text: String,
        exhibitors: Array,
    },
    data()
    {
        return {
            show: false,
            selectedExhibitorId: null,
            showSelectedExhibitor: false
        }
    },
    computed:
    {
        selectedExhibitor()
        {
            var result = null;

            this.$store.state.exhibitors.forEach((exhibitorWithData)=>{
                if (exhibitorWithData.id === this.selectedExhibitorId)
                {
                    result = exhibitorWithData;
                }
            });

            return result;
        },
        exhibitorsWithData()
        {
            var result = [];
            this.exhibitors.forEach((exhibitorReference)=>{
                var contentId = exhibitorReference.exhibitor;
                this.$store.state.exhibitors.forEach((exhibitorWithData)=>{
                    if (exhibitorWithData.id === contentId)
                    {
                        result.push(exhibitorWithData);
                    }
                });
            });
            return result;
        }
    },
    mounted()
    {
        this.$nextTick(()=>{
            this.show = true;
        });
    },
    created()
    {
        eventBus.emit('turntable.disableDemoMode');
    },
    unmounted()
    {    
        eventBus.emit('turntable.enableDemoMode');
    },
    methods:
    {
        // NOTE: Hack because I could not get <transition> to work properly around this component...
        close()
        {
            this.show = false;
            setTimeout(() => {
                this.$emit('close');
            }, 250);
        },
        getUmbracoMediaItemSrc(mediaItem)
        {
            return getUmbracoMediaItemSrc(mediaItem);
        }
    }
    // TODO: Send events to deactivate/activate Turntable rendering
}
</script>
<template>
    <div class="loading-screen">
        Loading...
    </div>
</template>

<script>
export default {
    name: 'LoadingScreen',
    props: {},
    data()
    {
        return {}
    },
    created()
    {
        // console.log('this.isLoading', this.isLoading);
        // console.log('this.showroom', this.showroom);
    },
    methods:
    {}
}
</script>

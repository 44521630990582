<template>
  <div class="aspect-ratio" ref="appFrame" :data-scale="scale">
    <slot></slot>
  </div>
</template>

<script>
export default {
    name: 'AspectRatio', // TODO(Mølby): Find a better name... AppFrame?
    props: {},
    data: ()=>{
        return {
            scale: 1
        }
    },
    mounted()
    {
        this.updateFrameSize();
        window.addEventListener('resize', this.updateFrameSize);
    },
    methods:
    {
        updateFrameSize()
        {
            var appFrame = this.$refs.appFrame;

            // NOTE(Mølby): Scaling handler. uses css transform: scale on the #app id to scale the entire scene.
            // The math to determine how much scale to apply to the scene. 
            // It will make the app as big as it can be while maintaining its aspect ratio. The app will be centered.

            // NOTE(Mølby): Default settings
            var aspectRatioOptions = {
                centerVertically: true,
                centerHorizontically: true
            };

            var windowResolution = {
                width: window.innerWidth,
                height: window.innerHeight
            };

            var appResolution = {
                width: appFrame.offsetWidth,
                height: appFrame.offsetHeight
            }

            var windowAspectRatio = windowResolution.width / windowResolution.height;
            var appAspectRatio =  appResolution.width / appResolution.height;

            var windowAspectRatioIsTallerThanApp = (windowAspectRatio > appAspectRatio);

            if (windowAspectRatioIsTallerThanApp)
            {
                // NOTE(Mølby): Window is taller than app
                // NOTE(Mølby): Resize app based on height
                var heightDifference = windowResolution.height / appResolution.height;
                appFrame.style.transform = ("scale(" + heightDifference + ")");     
                this.scale = heightDifference;
            }
            else
            {
                // NOTE(Mølby): Window is wider than app
                // NOTE(Mølby): Resize app based on width
                var widthDifference = windowResolution.width / appResolution.width;
                appFrame.style.transform = ("scale(" + widthDifference + ")");
                this.scale = widthDifference;
            }

            
            // NOTE(Mølby): Center the app container on both axis

            var appClientRect = appFrame.getBoundingClientRect();
            var appResolutionScaled = {
                width: appClientRect.width,
                height: appClientRect.height
            }

            var appOffset = {
                x: 0,
                y: 0
            };

            if (aspectRatioOptions.centerHorizontically && (appResolutionScaled.width < windowResolution.width))
            {
                appOffset.x = (windowResolution.width - appResolutionScaled.width) / 2;
            }

            if (aspectRatioOptions.centerVertically && (appResolutionScaled.height < windowResolution.height))
            {
                appOffset.y = (windowResolution.height - appResolutionScaled.height) / 2;
            }

            appFrame.style.transformOrigin = 'top left';
            appFrame.style.left = appOffset.x+'px';
            appFrame.style.top = appOffset.y+'px';
            appFrame.style.margin = '0';
        }
    }
}
</script>

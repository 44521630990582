<template>
  <div class="overlayContainer" :style="'color: '+textColor">
    <div :class="[globalColor == '#a3ea8f' ? 'backBtn-green' : 'backBtn']" @click="goBack()" v-if="showBackButton"></div>
    <p class="subtitle" v-html="subtitle"></p>
    <h1 v-html="title"></h1>
    <img v-if="topRightImage" class="topRight" :src="getUmbracoMediaItemSrc(topRightImage)" />
    <img v-if="bottomRightImage" :class="[logoImages ? 'topRightSecond' : 'bottomRight']" :src="getUmbracoMediaItemSrc(bottomRightImage)" />
    <div class="overlayLogoContainer">
      <img v-for="logoImage in logoImages" :key="logoImage" :src="getUmbracoMediaItemSrc(logoImage)" />
    </div>
  </div>
</template>

<style>
</style>
<script>
import { getUmbracoMediaItemSrc } from '@/helpers/assetHelper';
import {goToPathByID, goToParentScene} from '@/helpers/navigationHelper';


export default {
  name: 'OverlayTitle',
  props:
  {
      title: String,
      subtitle: String,
      textColor: String,
      topRightImage : Object,
      bottomRightImage : Object,
      logoImages : Object,
      showBackButton : Boolean,
      globalColor : String
  },
  computed:
  {
  },
  methods:
  {
    onClick(tab)
    {
      goToPathByID(tab.sceneReference);
    },
    getUmbracoMediaItemSrc(mediaItem)
    {
        return getUmbracoMediaItemSrc(mediaItem);
    },
    goBack(){
      goToParentScene();
    }
  }
}
</script>

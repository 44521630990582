import { createStore } from 'vuex'
import pad from '@/helpers/pad';
import config from '@/config';
import { preloadImages, getUmbracoMediaItemSrc } from '@/helpers/assetHelper';

export default createStore({

  state:
  {
    manifestWasLoaded: false,
    isLoading: false,
    scenes: [],
    contentComponents: [],
    sceneTransitionEffect: {type: 'none'},
    exhibitors: [],
    businesses: []
  },
  
  mutations:
  {
    setLoadingState(state, payload)
    {
      state.isLoading = payload;
    },

    setMeasuringUnit(state, payload)
    {
      state.measuringUnit = payload;
    },

    setSceneTransitionEffect(state, payload)
    {
      state.sceneTransitionEffect = {
        type: payload.type,
        transformOrigin: payload.transformOrigin
      }
    },

    attatchSortedManifestData (state, payload)
    {
      let sortedManifest = payload;

      state.scenes = sortedManifest.scenes;
      state.contentComponents = sortedManifest.contentComponents;
      state.exhibitors = sortedManifest.exhibitors;
      state.businesses = sortedManifest.businesses;
    }
  },

  actions:
  {
    loadManifest(context, settings)
    {
      context.commit('setLoadingState', true);

      let manifestPath = config.manifestPath;

      let fetchManifestPromise = fetch(manifestPath, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-store',
        credentials: 'same-origin',
        headers: {
            // 'Content-Type': 'application/json'
        },
      })
      .then(
        (response) => {
            return response.json();
        }
      );

      fetchManifestPromise.then(
        (data) => {
          let manifestData = data;
          console.info(manifestData);
          let sortedManifest = sortManifest(manifestData);

          // NOTE: Add a minimum delay to ensure the loading screen dosen't "flash" awkwardly on screen when loading happens instantly.
          let delayPromise = new Promise((resolve/*, reject*/)=>{
            setTimeout(() => {
                resolve();
            }, 500);
          });

          // NOTE: Preload images here

          // NOTE: Scene images
          let imageUrlsToPreload = [];
          sortedManifest.scenes.forEach((scene)=>{
            if (scene.data && scene.data.backgroundImage)
            {
              let imageSrc = getUmbracoMediaItemSrc(scene.data.backgroundImage);
              imageUrlsToPreload.push(imageSrc);
            }
          });

          // NOTE: business images
          sortedManifest.businesses.forEach((business)=>{
            console.log("Preloading:", business);
            if(business.data && business.data.logo){
              let imageSrc = getUmbracoMediaItemSrc(business.data.logo);
              imageUrlsToPreload.push(imageSrc);
            }
          });

          // NOTE: Preload 360 assets here
          const numberOfFrames = 360;
          const indexOfFirstFrame = 0;
          for (let i=indexOfFirstFrame; i < numberOfFrames+indexOfFirstFrame; i++)
          {
              (()=>
              {
                let filePathName = config.tempMediaPath+'turntable/wind/MASTER - SoG - 360_'+pad(i+indexOfFirstFrame, 5)+'.webp';
                  imageUrlsToPreload.push(filePathName);
              })();
          }
          for (let i=indexOfFirstFrame; i < numberOfFrames+indexOfFirstFrame; i++)
          {
              (()=>
              {
                let filePathName = config.tempMediaPath+'turntable/SoG - MASTER - 360_'+pad(i+indexOfFirstFrame, 5)+'.webp';
                  imageUrlsToPreload.push(filePathName);
              })();
          }

          let useHighMemoryMode = false;
          if (settings.useHighMemoryMode)
          {
            useHighMemoryMode = settings.useHighMemoryMode;
          }

          let preloadImagesPromise = preloadImages(imageUrlsToPreload, useHighMemoryMode);
          Promise.all([preloadImagesPromise, delayPromise])
            .then(()=>{
              context.commit('attatchSortedManifestData', sortedManifest);
              context.commit('setLoadingState', false);
            });

        });
    }
  }

});

// NOTE: Iterate the manifest data and map it to a more convenient structure
function sortManifest(manifestData)
{
  let sortedManifest = {
    scenes: [],
    contentComponents: [],
    exhibitors: [],
    businesses: []
  };

  let expoRoot = null;
  if (manifestData.custom && manifestData.custom.length > 0 && manifestData.custom[0].type === 'expoSite')
  {
    expoRoot = manifestData.custom[0];
  }
  else
  {
    // TODO: Throw an error
  }
  
  expoRoot.Children.forEach((node)=>{

    // NOTE: Get scenes
    if (node.type === 'sceneRoot' || node.type === 'scene')
    {
      let scenes = []
      let contentComponents = [];
      recursivelySortScenes(node, null, scenes, contentComponents);

      sortedManifest.scenes = scenes;
      sortedManifest.contentComponents = contentComponents;
    }
    // NOTE: Get products
    else if (node.type === 'exhibitors')
    {
      var exhibitors = [];

      if (node.Children)
      {
        node.Children.forEach((childNode)=>{

          if (childNode.type === 'exhibitor')
          {
            exhibitors.push({
              id: childNode.id,
              data: childNode.data
            });
          }

        });
      }

      sortedManifest.exhibitors = exhibitors;
    }
    else if (node.type === 'businesses')
    {
      var businesses = [];

      if (node.Children)
      {
        node.Children.forEach((childNode)=>{

          if (childNode.type === 'business')
          {
            businesses.push({
              id: childNode.id,
              data: childNode.data
            });
          }

        });
      }

      sortedManifest.businesses = businesses;
    }
  });

  return sortedManifest;
}

function recursivelySortScenes(node, parentScene, arrayOfScenes, arrayOfContentComponents)
{  
  let scene = {
    id: node.id,
    parentId: (parentScene ? parentScene.id : null),
    slug: node.data.slug,
    path: (parentScene ? (parentScene.path + node.data.slug + '/') : '/'),
    title: node.data.title,
    type: node.type,
    sceneComponents: node.data.sceneComponents,
    data: node.data // NOTE: Just add all remaining data here
  };
  arrayOfScenes.push(scene);

  if (node.Children)
  {
    node.Children.forEach((childNode)=>{
      if (childNode.type == "scene")
      {
        recursivelySortScenes(childNode, scene, arrayOfScenes, arrayOfContentComponents);
      }
      // NOTE: Assume everything else is a content component
      else
      {
        let contentComponent = {
          id: childNode.id,
          scene: scene,
          slug: childNode.data.slug,
          path: scene.path + childNode.data.slug + '/',
          type: childNode.type,
          data: childNode.data // NOTE: Just add all remaining data here
        }
        arrayOfContentComponents.push(contentComponent);
      }
    });
  }

  return arrayOfScenes;
}
<template>
    <div class="contentPageContainer">
        <div :class="[globalColor == '#a3ea8f' ? 'contentPageCloseBtn-green' : 'contentPageCloseBtn']" @click="close()"></div>
        <div class="content" ref="content">
          <div class="contentDynamic">
            <p class="contentSubtitle">{{subtitle}}</p>
            <h2 class="contentTitle">{{title}}</h2>

            <div class="contentMenuBtnContainer">
                <div :class="{'contentMenuBtn-active': tab == activeTab}" class="contentMenuBtn" v-for="tab in tabs" :key="tab" @click="setActiveTab(tab)">{{tab.title}}</div>
            </div>
            <template v-if="activeTab">
                <div class="contentText" v-html="activeTab.text"></div>
            </template>
            <template v-else>
                <div class="contentText" v-html="text"></div>
            </template>
          </div>
          <div class="contentStatic" :style="{'height': contentStaticHeight + 'px'}">
            <div class="ImageContainerStatic">
              <template v-if="activeTab">
                    <template v-if="activeTab.media">
                        <img v-if="getUmbracoMediaItemSrc(activeTab.media).type == 'image'" :src="getUmbracoMediaItemSrc(activeTab.media).src" />
                        <video v-if="getUmbracoMediaItemSrc(activeTab.media).type == 'video'" :src="getUmbracoMediaItemSrc(activeTab.media).src"></video>
                    </template>
                </template>
                <template v-else>
                    <template v-if="image">
                        <img v-if="getUmbracoMediaItemSrc(image).type == 'image'" :src="getUmbracoMediaItemSrc(image).src" />
                        <video v-if="getUmbracoMediaItemSrc(image).type == 'video'" :src="getUmbracoMediaItemSrc(image).src"></video>
                    </template>
                </template>
            </div>
          </div>
        </div>
        <div class="lists">
          <img class="contentPageLine" :src="getUmbracoMediaItemSrc(backgroundLine).src">
            <div class="listsHeadline">
              <h2 v-if="activeTab && activeTab.descriptiveHeadline">{{activeTab.descriptiveHeadline}}</h2>
            </div>
            <div class="tabContainer">
              <div class="tab tab1" v-if="tabs && businessesTab1.length > 0">
                  <div :class="{'tabNavigation-left-disabled': tab1Slide == 0}" class="tabNavigation-left" @click="slideBusinesses(1, false, '.tab1')"></div>
                  <div :class="{'tabNavigation-right-disabled': tab1Slide >= (this.businessesTab1.length * 207) - (207 * 3)}" class="tabNavigation-right" @click="slideBusinesses(1, true, '.tab1')"></div>
                  <h3 v-if="activeTab">{{activeTab.tab1Name}}</h3>
                  <h3 v-if="!activeTab">{{tabs[0].tab1Name}}</h3>
                  <div class="businessScroll">
                  <div class="businessScrollSlide" :style="'width:' + businessesTab1.length * 207 + 'px'">
                      <div class="businessContainer" v-for="business in businessesTab1" :key="business">
                      <div class="imgContainer">
                          <img :src="getUmbracoMediaItemSrc(business.logo).src">
                      </div>
                      <div class="businessText">
                        <h3>{{business.name}}</h3>
                        <div v-html="business.text"></div>
                        <div v-if="business.link" class="businessCTA" @click="navigateToBusinessTab(business.link)">
                          <div class="text">Read More</div>
                          <div :class="[globalColor == '#a3ea8f' ? 'button-green' : 'button']" class="button"></div>
                        </div>
                      </div>
                      </div>
                  </div>
                  </div>
              </div>
              <div class="tab tab2" v-if="tabs && businessesTab2.length > 0">
                  <div :class="{'tabNavigation-left-disabled': tab2Slide == 0}" class="tabNavigation-left" @click="slideBusinesses(2, false, '.tab2')"></div>
                  <div :class="{'tabNavigation-right-disabled': tab2Slide >= (this.businessesTab2.length * 207) - (207 * 3)}" class="tabNavigation-right" @click="slideBusinesses(2, true, '.tab2')"></div>
                  <h3 v-if="activeTab">{{activeTab.tab2Name}}</h3>
                  <h3 v-if="!activeTab">{{tabs[0].tab2Name}}</h3>
                  <div class="businessScroll">
                  <div class="businessScrollSlide" :style="'width:' + businessesTab2.length * 207 + 'px'">
                      <div class="businessContainer" v-for="business in businessesTab2" :key="business">
                      <div class="imgContainer">
                          <img :src="getUmbracoMediaItemSrc(business.logo).src">
                      </div>
                      <div class="businessText">
                        <h3>{{business.name}}</h3>
                        <div v-html="business.text"></div>
                        <div v-if="business.link" class="businessCTA" @click="navigateToBusinessTab(business.link)">
                          <div class="text">Read More</div>
                          <div :class="[globalColor == '#a3ea8f' ? 'button-green' : 'button']" class="button"></div>
                        </div>
                      </div>
                      </div>
                  </div>
                  </div>
              </div>
              <div class="tab tab3" v-if="tabs && businessesTab3.length > 0">
                  <div :class="{'tabNavigation-left-disabled': tab3Slide == 0}" class="tabNavigation-left" @click="slideBusinesses(3, false, '.tab3')"></div>
                  <div :class="{'tabNavigation-right-disabled': tab3Slide >= (this.businessesTab3.length * 207) - (207 * 3)}" class="tabNavigation-right" @click="slideBusinesses(3, true, '.tab3')"></div>
                  <h3 v-if="activeTab">{{activeTab.tab3Name}}</h3>
                  <h3 v-if="!activeTab">{{tabs[0].tab3Name}}</h3>
                  <div class="businessScroll">
                  <div class="businessScrollSlide" :style="'width:' + businessesTab3.length * 207 + 'px'">
                      <div class="businessContainer" v-for="business in businessesTab3" :key="business">
                      <div class="imgContainer">
                          <img :src="getUmbracoMediaItemSrc(business.logo).src">
                      </div>
                      <div class="businessText">
                        <h3>{{business.name}}</h3>
                        <div v-html="business.text"></div>
                        <div v-if="business.link" class="businessCTA" @click="navigateToBusinessTab(business.link)">
                          <div class="text">Read More</div>
                          <div :class="[globalColor == '#a3ea8f' ? 'button-green' : 'button']" class="button"></div>
                        </div>
                      </div>
                      </div>
                  </div>
                  </div>
              </div>
            </div>
        </div>
        <div class="businessLink" v-if="activeBusinessLink">
          <div class="businessIframeContainer">
            <div class="contentPageCloseBtn" style="top: 105px; right: 144px;" @click="navigateToBusinessTab(null)"></div>
            <iframe class="businessLinkIframe" :src="activeBusinessLink" />
          </div>
        </div>
    </div>
</template>
<style>
  .contentMenuBtn-active{
    background-color: v-bind(globalColor);
  }
  .contentMenuBtn:hover{
    color: v-bind(globalColor);
  }
  .contentMenuBtn-active:hover{
    color: #f8f8f8;
  }
</style>
<script>
import { getUmbracoMediaItemSrc } from '@/helpers/assetHelper';
import {goToPathByID, closeComponentIfOpen, getBusinessesContentByID} from '@/helpers/navigationHelper';

export default {
    name: 'ContentPagePopup',
    props: {
        title: String,
        subtitle: String,
        text: String,
        image: Object,
        tabs : Array,
        backgroundLine : Object,
        globalColor: String
    },
  data() {
      return {
        show: false,
        activeTab : null,
        tab1Slide : 0,
        tab2Slide : 0,
        tab3Slide : 0,
        activeBusinessLink : null,
        contentStaticHeight: 0
      }
  },
  computed:
  {
    businessesTab1() {
      let result = [];
      if(this.activeTab != undefined && this.activeTab.tab1Businesses){
        this.activeTab.tab1Businesses.forEach(element => {
          result.push({
              name : this.getContent(element.business).data.data.businessName,
              link : this.getContent(element.business).data.data.link,
              logo : this.getContent(element.business).data.data.logo,
              text : element.text
          });
        });
      }else{
        this.tabs.forEach(tab => {
          tab.tab1Businesses.forEach(element => {

            let business = {
                name : this.getContent(element.business).data.data.businessName,
                link : this.getContent(element.business).data.data.link,
                logo : this.getContent(element.business).data.data.logo,
                text : element.text
            };
            result.push(business);
          });
        });
        result = result.reduce((acc, user) => {
          if (!acc.find(u => u.name === user.name)) {
              acc.push(user)
          }
          return acc
        }, [])
      }
      return result;
    },
    businessesTab2() {
      let result = [];
        if(this.activeTab != undefined && this.activeTab.tab2Businesses){
            this.activeTab.tab2Businesses.forEach(element => {
            result.push({
                name : this.getContent(element.business).data.data.businessName,
                link : this.getContent(element.business).data.data.link,
                logo : this.getContent(element.business).data.data.logo,
                text : element.text
            });
            });
        }else{
        this.tabs.forEach(tab => {
          tab.tab2Businesses.forEach(element => {

            let business = {
                name : this.getContent(element.business).data.data.businessName,
                link : this.getContent(element.business).data.data.link,
                logo : this.getContent(element.business).data.data.logo,
                text : element.text
            };
            result.push(business);
          });
        });
        result = result.reduce((acc, user) => {
          if (!acc.find(u => u.name === user.name)) {
              acc.push(user)
          }
          return acc
        }, [])
      }
        return result;
    },
    businessesTab3() {
      let result = [];
        if(this.activeTab != undefined && this.activeTab.tab3Businesses){
            this.activeTab.tab3Businesses.forEach(element => {
            result.push({
                name : this.getContent(element.business).data.data.businessName,
                link : this.getContent(element.business).data.data.link,
                logo : this.getContent(element.business).data.data.logo,
                text : element.text
            });
            });
        }else{
        this.tabs.forEach(tab => {
          tab.tab3Businesses.forEach(element => {

            let business = {
                name : this.getContent(element.business).data.data.businessName,
                link : this.getContent(element.business).data.data.link,
                logo : this.getContent(element.business).data.data.logo,
                text : element.text
            };
            result.push(business);
          });
        });
        result = result.reduce((acc, user) => {
          if (!acc.find(u => u.name === user.name)) {
              acc.push(user)
          }
          return acc
        }, [])
      }
        return result;
    }
  },
  methods:
  {
    onClick(tab)
    {
      goToPathByID(tab.sceneReference);
    },
    getUmbracoMediaItemSrc(mediaItem)
    {
        let value = getUmbracoMediaItemSrc(mediaItem);
        let video = ["mp4","mov","wmv","avi"];
        let image = ["jpg","jpeg", "png", "svg", "webp"];
        let valueType = (/[.]/.exec(value)) ? /[^.]+$/.exec(value) : undefined;
        let type = null;

        if(video.find( x => x == valueType[0])){
          type = "video";
        }else if(image.find( x => x == valueType[0])){
          type = "image";
        }
        return { type : type, src : value };
    },
    close()
    {
      closeComponentIfOpen();
    },
    getContent(id){
      return getBusinessesContentByID(id);
    },
    setActiveTab(tab){
        console.log(tab);
        if(this.activeTab == tab){
            this.activeTab = null;
            this.tab1Slide = 0;
            this.tab2Slide = 0;
            this.tab3Slide = 0;
        }else{
            this.activeTab = tab;
            this.tab1Slide = 0;
            this.tab2Slide = 0;
            this.tab3Slide = 0;
        }
        this.triggerImageHeight();
    },
    triggerImageHeight(){
      let self = this;
      setTimeout(() => {
        let childOffset = 0;
        for (let index = 0; index < document.querySelector('.contentText').children.length; index++) {
          childOffset = childOffset + document.querySelector('.contentText').children[index].offsetHeight;
        }
        let totalValue = 1080;
        let offsets = document.querySelector(".contentSubtitle").offsetHeight + document.querySelector(".contentTitle").offsetHeight + document.querySelector(".contentMenuBtnContainer").offsetHeight + childOffset;
        let marginValue = 36 + 15 + 40 + 45 + 45;
        self.contentStaticHeight = totalValue - (marginValue + offsets);
      }, 10);
    },
    slideBusinesses(tab, forward, className){
      console.log("Slide Function");
      switch (tab) {
        case 1:
          if(!forward && this.tab1Slide > 0){
            this.tab1Slide = this.tab1Slide - 207;
            document.querySelector(className).querySelector(".businessScrollSlide").style.marginLeft = -this.tab1Slide + "px";
          }else if(forward && this.tab1Slide < ((this.businessesTab1.length * 207) - (207 * 3))){
            this.tab1Slide = this.tab1Slide + 207;
            document.querySelector(className).querySelector(".businessScrollSlide").style.marginLeft = -this.tab1Slide + "px";
          }
          break;

        case 2:
          if(!forward && this.tab2Slide > 0){
            this.tab2Slide = this.tab2Slide - 207;
            document.querySelector(className).querySelector(".businessScrollSlide").style.marginLeft = -this.tab2Slide + "px";
          }else if(forward && this.tab2Slide < ((this.businessesTab2.length * 207) - (207 * 3))){
            this.tab2Slide = this.tab2Slide + 207;
            document.querySelector(className).querySelector(".businessScrollSlide").style.marginLeft = -this.tab2Slide + "px";
          }
          break;

        case 3:
          if(!forward && this.tab3Slide > 0){
            this.tab3Slide = this.tab3Slide - 207;
            document.querySelector(className).querySelector(".businessScrollSlide").style.marginLeft = -this.tab3Slide + "px";
          }else if(forward && this.tab3Slide < ((this.businessesTab3.length * 207) - (207 * 3))){
            this.tab3Slide = this.tab3Slide + 207;
            document.querySelector(className).querySelector(".businessScrollSlide").style.marginLeft = -this.tab3Slide + "px";
          }
          break;

        default:
          break;
      }
    },
    navigateToBusinessTab(link){
      this.activeBusinessLink = link;
      console.log(link);
    }
  },
  mounted() {
    this.triggerImageHeight();
  }
}
</script>
<template>
  <AspectRatio>

    <!--
    <template v-if="appStarted">
    -->
      <LoadingScreen v-if="isLoading"></LoadingScreen>
      <router-view></router-view>
    <!--
    </template>

    <template v-else>
      <button @click="startApp(false)">Start app using safe mode</button>
      <button @click="startApp(true)">Start app using high memory mode</button>
    </template>
    -->

  </AspectRatio>
</template>


<script>
import './scss/main.scss';
import AspectRatio from '@/components/AspectRatio';
import LoadingScreen from '@/components/LoadingScreen';

export default {
  name: 'App',
  components:
  {
    AspectRatio: AspectRatio,
    LoadingScreen: LoadingScreen
  },
  data()
  {
    return {
      // appStarted: false
    }
  },
  computed: 
  {
    isLoading()
    {
      return this.$store.state.isLoading;
    }
  },
  created()
  {
    // NOTE: Tell our Vuex store to load the content
    // TODO: Maybe rename to initialload? Since it might load a lot more than just the manifest...
    this.$store.dispatch('loadManifest', {useHighMemoryMode: false});
  },
  methods:
  {
    /*
    startApp(useHighMemoryMode)
    {
      this.$store.dispatch('loadManifest', {useHighMemoryMode: useHighMemoryMode});
      this.appStarted = true;
    }
    */
  }
}
</script>
<template>

  <!-- NOTE: We manually map data to vue components here -->
  <div>
    <ExhibitorsPopup v-if="contentComponentData && contentComponentData.type === 'exhibitorsPopup'" 
      :title="contentComponentData.data.popupTitle"
      :text="contentComponentData.data.text"
      :callToActionText="contentComponentData.data.callToActionText"
      :exhibitors="contentComponentData.data.exhibitors"
      @close="closeContentComponent"
    />

    <ContentPagePopup v-if="contentComponentData && contentComponentData.type === 'contentPagePopup'" 
      :title="contentComponentData.data.contentTitle"
      :subtitle="contentComponentData.data.contentSubtitle"
      :text="contentComponentData.data.contentText"
      :image="contentComponentData.data.contentImage"
      :backgroundLine="contentComponentData.data.backgroundLine"
      :tabs="contentComponentData.data.contentTabs"
      :globalColor="contentComponentData.data.globalColor"
      @close="closeContentComponent"
    />
  </div>
</template>

<script>
import {closeComponentIfOpen} from '@/helpers/navigationHelper';
import ExhibitorsPopup from '@/components/content-components/ExhibitorsPopup';
import ContentPagePopup from '@/components/content-components/ContentPagePopup';

export default {
  name: 'ContentComponentRenderer',
  components: {
    ExhibitorsPopup,
    ContentPagePopup
  },
  props: {
      contentComponentData: Object
  },
  created()
  {
  },
  methods:
  {
    closeContentComponent()
    {
      closeComponentIfOpen();
    }
  }
}
</script>

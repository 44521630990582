<template>
  <div class="hotspot--info--container" :class="[{'hotspot--info--container--active': active}]" :style="['left:'+x+'px;top:'+y+'px;']">
    <div class="hotspot--info-closeBtn" @click="onClick()">
      <div class="hotspot--info-closeBtnImg"></div>
    </div>
    <h2 class="hotspot--info--title">{{title}}</h2>
    <div class="hotspot--info-description">
      {{description}}
    </div>
  </div>
</template>
<style>
  .hotspot--info--container{
    background-color: v-bind(globalColor);
  }
  .hotspot--info--container--active{
    background-color: v-bind(globalColor + "e8")!important;
  }
</style>
<script>
export default {
  name: 'HotspotInfo',
  props:
  {
      title: String,
      description: String,
      x: Number,
      y: Number,
      globalColor: String
  },
  data(){
    return{
      active: false
    }
  },
  methods:
  {
    onClick()
    {
      console.log("clicked");
      this.active = !this.active;
    }
  }
}
</script>

<template>
  <div class="contentPageContainer">
    <div class="contentPageCloseBtn" @click="goBack()"></div>
    <div class="content">
      <p>{{subtitle}}</p>
      <h2>{{title}}</h2>

      <div class="contentMenuBtnContainer">
        <div :class="{'contentMenuBtn-active': tab == activeTab}" class="contentMenuBtn" v-for="tab in tabs" :key="tab" @click="setActiveTab(tab)">{{tab.title}}</div>
      </div>

      <div class="contentMenus">

        <div class="contentText" v-html="activeTab.text"></div>

        <div class="mediaContent" v-if="activeTab.media">
          <img v-if="getUmbracoMediaItemSrc(activeTab.media).type == 'image'" :src="getUmbracoMediaItemSrc(activeTab.media).src" />
          <video v-if="getUmbracoMediaItemSrc(activeTab.media).type == 'video'" :src="getUmbracoMediaItemSrc(activeTab.media).src"></video>
        </div>
      </div>
    </div>
    <div class="lists">
      <img class="contentPageLine" :src="getUmbracoMediaItemSrc(backgroundLine).src">
      <div class="tab tab1" v-if="businessesTab1">
        <div :class="{'tabNavigation-left-disabled': tab1Slide == 0}" class="tabNavigation-left" @click="slideBusinesses(1, false)"></div>
        <div :class="{'tabNavigation-right-disabled': tab1Slide >= (this.businessesTab1.length * 207) - (207 * 3)}" class="tabNavigation-right" @click="slideBusinesses(1, true)"></div>
        <h3>{{activeTab.tab1Name}}</h3>
        <div class="businessScroll">
          <div class="businessScrollSlide" :style="'width:' + businessesTab1.length * 207 + 'px'">
            <div class="businessContainer" v-for="business in businessesTab1" :key="business" @click="navigateToBusinessTab(business.link)">
              <div class="imgContainer">
                <img :src="getUmbracoMediaItemSrc(business.logo).src">
              </div>
              <div class="businessText" v-html="business.tab1Text"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab tab2" v-if="businessesTab2">
        <div :class="{'tabNavigation-left-disabled': tab2Slide == 0}" class="tabNavigation-left" @click="slideBusinesses(2, false)"></div>
        <div :class="{'tabNavigation-right-disabled': tab2Slide >= (this.businessesTab2.length * 207) - (207 * 3)}" class="tabNavigation-right" @click="slideBusinesses(2, true)"></div>
        <h3>{{activeTab.tab2Name}}</h3>
        <div class="businessScroll">
          <div class="businessScrollSlide" :style="'width:' + businessesTab2.length * 207 + 'px'">
            <div class="businessContainer" v-for="business in businessesTab2" :key="business" @click="navigateToBusinessTab(business.link)">
              <div class="imgContainer">
                <img :src="getUmbracoMediaItemSrc(business.logo).src">
              </div>
              <div class="businessText" v-html="business.tab2Text"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab tab3" v-if="businessesTab3">
        <div :class="{'tabNavigation-left-disabled': tab3Slide == 0}" class="tabNavigation-left" @click="slideBusinesses(3, false)"></div>
        <div :class="{'tabNavigation-right-disabled': tab3Slide >= (this.businessesTab3.length * 207) - (207 * 3)}" class="tabNavigation-right" @click="slideBusinesses(3, true)"></div>
        <h3>{{activeTab.tab3Name}}</h3>
        <div class="businessScroll">
          <div class="businessScrollSlide" :style="'width:' + businessesTab3.length * 207 + 'px'">
            <div class="businessContainer" v-for="business in businessesTab3" :key="business" @click="navigateToBusinessTab(business.link)">
              <div class="imgContainer">
                <img :src="getUmbracoMediaItemSrc(business.logo).src">
              </div>
              <div class="businessText" v-html="business.tab3Text"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="businessLink" v-if="activeBusinessLink">
      <div class="contentPageCloseBtn" @click="navigateToBusinessTab(null)"></div>
      <iframe class="businessLinkIframe" :src="activeBusinessLink" />
    </div>
  </div>
</template>

<script>
import { getUmbracoMediaItemSrc } from '@/helpers/assetHelper';
import {goToPathByID, goToParentScene, getBusinessesContentByID} from '@/helpers/navigationHelper';


export default {
  name: 'ContentPage',
  props:
  {
      title: String,
      subtitle: String,
      tabs : Array,
      backgroundLine : Object
  },
  data() {
      return {
        activeTab : this.tabs[0],
        tab1Slide : 0,
        tab2Slide : 0,
        tab3Slide : 0,
        activeBusinessLink : null
      }
  },
  computed:
  {
    
    businessesTab1() {
      let result = [];
      if(this.activeTab != undefined && this.activeTab.tab1Businesses){
        this.activeTab.tab1Businesses.forEach(element => {
          result.push(this.getContent(element).data.data);
        });
        console.log("businesses: ", result);
        return result;
      }else{
        return null;
      }
    },
    businessesTab2() {
      let result = [];
      if(this.activeTab != undefined && this.activeTab.tab2Businesses){
        this.activeTab.tab2Businesses.forEach(element => {
          result.push(this.getContent(element).data.data);
        });
        console.log("businesses: ", result);
        return result;
      }else{
        return null;
      }
    },
    businessesTab3() {
      let result = [];
      if(this.activeTab != undefined && this.activeTab.tab3Businesses){
        this.activeTab.tab3Businesses.forEach(element => {
          result.push(this.getContent(element).data.data);
        });
        console.log("businesses: ", result);
        return result;
      }else{
        return null;
      }
    }
  },
  methods:
  {
    onClick(tab)
    {
      goToPathByID(tab.sceneReference);
    },
    getUmbracoMediaItemSrc(mediaItem)
    {
        let value = getUmbracoMediaItemSrc(mediaItem);
        let video = ["mp4","mov","wmv","avi"];
        let image = ["jpeg", "png", "svg", "webp"];
        let valueType = (/[.]/.exec(value)) ? /[^.]+$/.exec(value) : undefined;
        let type = null;

        if(video.find( x => x == valueType[0])){
          type = "video";
        }else if(image.find( x => x == valueType[0])){
          type = "image";
        }
        console.log(valueType[0]);
        return { type : type, src : value };
    },
    goBack(){
      goToParentScene();
    },
    getContent(id){
      return getBusinessesContentByID(id);
    },
    setActiveTab(tab){
      this.activeTab = tab;
      this.tab1Slide = 0;
      this.tab2Slide = 0;
      this.tab3Slide = 0;
    },
    slideBusinesses(tab, forward){
      console.log(tab, forward);
      console.log(this.tab1Slide);
      console.log((this.businessesTab1.length * 207) - (207 * 3));
      switch (tab) {
        case 1:
          if(!forward && this.tab1Slide > 0){
            this.tab1Slide = this.tab1Slide - 207;
            document.querySelector(".tab1").querySelector(".businessScrollSlide").style.marginLeft = -this.tab1Slide + "px";
          }else if(forward && this.tab1Slide < ((this.businessesTab1.length * 207) - (207 * 3))){
            this.tab1Slide = this.tab1Slide + 207;
            document.querySelector(".tab1").querySelector(".businessScrollSlide").style.marginLeft = -this.tab1Slide + "px";
          }
          break;

        case 2:
          if(!forward && this.tab2Slide > 0){
            this.tab2Slide = this.tab2Slide - 207;
            document.querySelector(".tab2").querySelector(".businessScrollSlide").style.marginLeft = -this.tab2Slide + "px";
          }else if(forward && this.tab2Slide < ((this.businessesTab2.length * 207) - (207 * 3))){
            this.tab2Slide = this.tab2Slide + 207;
            document.querySelector(".tab2").querySelector(".businessScrollSlide").style.marginLeft = -this.tab2Slide + "px";
          }
          break;

        case 3:
          if(!forward && this.tab3Slide > 0){
            this.tab3Slide = this.tab3Slide - 207;
            document.querySelector(".tab3").querySelector(".businessScrollSlide").style.marginLeft = -this.tab3Slide + "px";
          }else if(forward && this.tab3Slide < ((this.businessesTab3.length * 207) - (207 * 3))){
            this.tab3Slide = this.tab3Slide + 207;
            document.querySelector(".tab3").querySelector(".businessScrollSlide").style.marginLeft = -this.tab3Slide + "px";
          }
          break;

        default:
          break;
      }
    },
    navigateToBusinessTab(link){
      this.activeBusinessLink = link;
      console.log(link);
    }
  },
  mounted() {
    console.log(this.activeTab);
  }
}
</script>

<template>
    <div class="hotspot-3d-canvas" ref="canvasParent">
        <div class="hotspot-3d-canvas__hotspots">
            <div class="hotspot-button hotspot-button--360-hotspot"
                v-for="hotspot,i in hotspotsWith2DPositions"
                :key="i"
                :style="['opacity: '+hotspot.opacity, 'left:'+hotspot.x+'px;top:'+hotspot.y+'px;']"
                :class="[{'hotspot-button--selected': hotspot.isSelected}, {'hotspot-button--near-center': hotspot.isNearCenter}]">
                    <template v-if="!hotspot.minorHotspot">
                        <div class="hotspot-button__inner"
                            @click="handleHotspotClick(hotspot)">
                                <div class="hotspot-button__title">{{hotspot.title}}</div>
                                <div class="hotspot-button__icon" :style="'background-color:'+globalColor"></div>
                        </div>
                        <div class="hotspot-button__line" :style="'height:'+hotspot.lineHeight+'px'"></div>
                    </template>
                    <template v-if="hotspot.minorHotspot">
                        <div class="hotspot-button__inner__minor"  :class="{'hotspot-button__inner__minor__active': currentTheme && hotspot.theme == currentTheme.title}"
                            @click="handleHotspotClick(hotspot)">
                                <div class="hotspot-button__title__minor"></div>
                                <div class="hotspot-button__title__minor__pulse"></div>
                        </div>
                    </template>
            </div>
        </div>
        <div class="selectedThemeText" :class="{'selectedThemeTextOpen' : currentTheme}">
            <div :class="[globalColor == '#a3ea8f' ? 'contentPageCloseBtn-green' : 'contentPageCloseBtn']" @click="selectTheme(null)"></div>
            <div class="selectedThemeLeftBar"></div>
            <div class="selectedThemeScroll">
                <h3 v-if="currentTheme">{{currentTheme.heading}}</h3>
                <div v-if="currentTheme" class="selectedThemeDescription" v-html="currentTheme.description"></div>
            </div>
            <div class="scrollBottomFade"></div>
        </div>
        <div class="minorHotspotThemes">
            <div style="position: absolute;">
                <h4 v-html="themesHeading"></h4>
                <div class="minorTheme" :class="{'minorThemeSelected' : currentTheme && theme.title == currentTheme.title}" v-for="theme, i in themes" :key="i" @click="selectTheme(theme)">{{theme.title}}</div>
            </div>
        </div>
        <div class="minorHotspotContent" v-if="activeMinorHotspot && currentTheme">
            <div :class="[globalColor == '#a3ea8f' ? 'contentPageCloseBtn-green' : 'contentPageCloseBtn']" @click="selectActiveMinorHotspot(null)"></div>
            <div class="minorHotspotScrollContent">
                <img v-if="activeMinorHotspot.media" :src="getUmbracoMediaItemSrc(activeMinorHotspot.media)" />
                <h3>{{activeMinorHotspot.heading}}</h3>
                <div class="minorHotspotContentDescription" v-html="activeMinorHotspot.description"></div>
            </div>
        </div>
    </div>
</template>

<style>
    .selectedThemeLeftBar{
        background-color: v-bind(globalColor);
    }
    .selectedThemeText{
        background-color: v-bind(secondaryGlobalColor);
    }
    .scrollBottomFade{
        background: linear-gradient(180deg, #84D8FF00 0%, v-bind(secondaryGlobalColor) 100%);
    }
    .minorHotspotThemes .minorThemeSelected {
        background-color: v-bind(globalColor);
    }
    .minorHotspotThemes .minorTheme:hover {
        color: v-bind(globalColor);
    }
    .minorHotspotThemes .minorThemeSelected:hover {
        color: #363636;
    }

    .selectedThemeText::-webkit-scrollbar{
        width: 40px;
        background-color: v-bind(globalColor);
    }

    /*Change this Maybe?*/
    .minorHotspotContent{
        background-color: v-bind(globalColor);
    }
</style>

<script>
import {goToPathByID} from '@/helpers/navigationHelper';
import { getUmbracoMediaItemSrc } from '@/helpers/assetHelper';
import {Scene, PerspectiveCamera, WebGLRenderer, Object3D, Math, Vector3, /*SphereGeometry,*/ /*AmbientLight,*/ /*CylinderGeometry,*/ /*TextureLoader,*/ /*MeshPhongMaterial,*/ /*MeshBasicMaterial, Mesh,*/ /*PointLight*/} from 'three';

// NOTE(Mølby): Three js variables. I'm putting them in module scope since vue does stuff to them that breaks three js if I put them in component state.
// TODO: Make sure to clear these on destroy, as they belong to the module instance (and therefore persists)

var scene;
var camera;
var renderer;
var parentTransform;

var hotspotPositionObjects = [];
var vectorWorldPos = new Vector3();
var vector = new Vector3();

export default {
	name: 'RealtimeGlobeWithMarkers',
	props: {
        enabled: Boolean,
        hotspots: Array,
        themesHeading: String,
        themes: Array,
		rotation: Number,
        globalColor: String
    },
	data()
	{
		return {
            currentTheme: null,
            initialized: false,
            hotspot2DPositions: [],
            activeMinorHotspot: null,
            clearUpdateLoop: false
        }
	},
    watch:
    {
        // TODO: React to changes to hotspots?
    },
    computed:
    {
        secondaryGlobalColor(){
            if(this.globalColor == "#a3ea8f"){
                return "#D1F4C7"
            }else{
                return "#84D8FF"
            }
        },
        hotspotsWith2DPositions()
        {
            let result = [];
            this.hotspots.forEach((hotspot, i)=>{
                let position = this.hotspot2DPositions[i];

                // NOTE: Determine if near center
                const distanceToCenterToBeNearCenter = 30;
                let isNearCenter = hotspot.minorHotspot;
                let distanceToCenter = this.calcDistanceWrapped(hotspot.positionDegrees, -this.rotation);
                if (distanceToCenter < distanceToCenterToBeNearCenter)
                {
                    isNearCenter = true;
                }
    
                if (position)
                {
                    result.push({
                        title: hotspot.title,
                        contentReference: hotspot.contentReference,
                        minorHotspot: hotspot.minorHotspot,
                        theme: hotspot.theme,
                        heading: hotspot.heading,
                        description: hotspot.description,
                        media: hotspot.media,
                        x: position.x,
                        y: position.y,
                        opacity: position.opacity,
                        lineHeight: position.lineHeight,
                        positionDegrees: hotspot.positionDegrees,
                        isSelected: (distanceToCenter === 0),
                        isNearCenter: isNearCenter
                    });
                }
            });
            return result;
        },
    },
	mounted()
	{
        scene = new Scene();

        camera = new PerspectiveCamera(45, 1920/1920, 0.1, 35000*4/*1000*/);

        // NOTE: Configure positions to match prerendered stuff
        camera.position.z = 96954.8;
        camera.position.y = 36411.7;
        camera.rotation.x = -0.428; // 1.142573; //-0.36388888888; //-0.63611111111;

        renderer = new WebGLRenderer({antialias:true, alpha: true});
        renderer.setSize(1920, 1920);

        this.$refs.canvasParent.appendChild(renderer.domElement);

        /*
        var cylinderGeometry = new CylinderGeometry(35000, 35000, 6500, 24*2);
        var sphereGeometry = new SphereGeometry( 1, 16, 16 );

        var globeMaterial = new MeshBasicMaterial( { color: 0x000000 } );
        globeMaterial.wireframe = true;
        globeMaterial.wireframelineWidth = 5;

        var debugSphereMaterial = new MeshBasicMaterial( { color: 0xFF00FF } );

        */

        parentTransform = new Object3D();
        // parentTransform.position.y = -6500/2;
        scene.add(parentTransform);

        /*

        //var globeSphere = new Mesh(sphereGeometry, globeMaterial);
        var globeSphere = new Mesh(cylinderGeometry, globeMaterial);

        // NOTE: Add debug globe
        var globeTransform = new Object3D();
        parentTransform.add(globeTransform);
        globeTransform.add(globeSphere);

        globeTransform.position.y = -6500/2;

        */

        // NOTE: Add hotspots
        this.hotspots.forEach((hotspot)=>{
            //var debugSphere = new Mesh(sphereGeometry, debugSphereMaterial);

            var hotspotObj = new Object3D();
            parentTransform.add(hotspotObj);
            hotspotObj.position.set(hotspot.pos.x, hotspot.pos.y, hotspot.pos.z);
            hotspotObj.minor = hotspot.minorHotspot;
            //parentTransform.add(debugSphere);
            //debugSphere.position.set(hotspot.pos.x, hotspot.pos.y, hotspot.pos.z);
            // debugSphere.scale.set(0.02,0.02,0.02);
            // hotspotPositionObjects.push(debugSphere);

            hotspotPositionObjects.push(hotspotObj);
        });


        this.initialized = true;

        this.updateLoop();
    },
    unmounted()
    {
        // NOTE(Mølby): This would be the place to properly destroy Three JS. (This will never unmount though.)
        console.log("Unmounted:", this);
        cancelAnimationFrame(this.updateLoop);
        scene = null;
        camera = null;
        renderer = null;
        parentTransform = null;
        this.clearUpdateLoop = true;
        hotspotPositionObjects = [];
        vectorWorldPos = new Vector3();
        vector = new Vector3();
    },
	methods:
	{
        selectTheme(theme){
            this.currentTheme = theme;
            this.activeMinorHotspot = null;
            document.querySelector(".selectedThemeScroll").scrollTop = 0;
            console.log(this.selectedTheme);
        },
        calcDistanceWrapped(posA, posB)
        {
            let distance = window.Math.abs(posA - posB);

            // NOTE: Handle wrapping...
            let distanceWrappedRight = window.Math.abs(posA - posB + 360);
            if (distanceWrappedRight < distance) {distance = distanceWrappedRight;}
            let distanceWrappedLeft = window.Math.abs((posA - 360) - posB);
            if (distanceWrappedLeft < distance) {distance = distanceWrappedLeft;}

            return distance;
        },
        handleHotspotClick(hotspot)
        {
            if(!hotspot.minorHotspot){
                if (hotspot.isSelected)
                {
                    document.cookie = "degreePosition="+ hotspot.positionDegrees;
                    goToPathByID(hotspot.contentReference);
                }
                else
                {
                    console.log("Going to: ", hotspot.positionDegrees)
                    this.$emit('goToPosition', hotspot.positionDegrees);
                }
            }else{
                if(this.currentTheme && this.currentTheme.title == hotspot.theme){
                    this.selectActiveMinorHotspot(hotspot);
                }
            }
        },
        selectActiveMinorHotspot(hotspot){
            this.activeMinorHotspot = hotspot;
        },
        calcHotspot2DPositions()
        {
            hotspotPositionObjects.forEach((hotspotObject, i)=>{
                
                hotspotObject.getWorldPosition(vectorWorldPos);
                vector.set(vectorWorldPos.x, vectorWorldPos.y, vectorWorldPos.z);

                let centerHotspot = hotspotObject.minor;

                // NOTE(Mølby): Map to 2D screen space
                vector.project(camera);

                // NOTE: Get actual size of canvas... (it is scaled so the math is off otherwise)
                // var canvasElement = this.$refs.canvasParent.querySelector('canvas');
                // var clientRect = canvasElement.getBoundingClientRect();

                let scale = 0.79; // NOTE: This matches CSS

                const canvasWidth = 1920;
                const canvasHeight = 1920;

                if(centerHotspot){
                    vector.x = window.Math.round( (   (vector.x / 2) * scale + 1 ) * canvasWidth  / 2 );
                    vector.y = window.Math.round( ( - (vector.y / 2) * scale + 0.78 ) * canvasHeight / 2 );
                    
                }else{
                    vector.x = window.Math.round( (   vector.x * scale + 1 ) * canvasWidth  / 2 );
                    vector.y = window.Math.round( ( - vector.y * scale + 0.97 ) * canvasHeight / 2 );
                    vector.z = 0;

                }
                // vector.x = window.Math.round( (   vector.x * scale + 1 ) * canvasWidth  / 2 );
                // vector.y = window.Math.round( ( - vector.y * scale + 1 ) * canvasHeight / 2 );
                // vector.z = 0;



                // NOTE: Determine line height
                var baseHeight = 94;
                if(centerHotspot){
                    baseHeight = baseHeight / 2;
                }
                var maxHeight = 316;
                let distanceToCenterNormalized = this.calcDistanceWrapped(this.hotspots[i].positionDegrees, -this.rotation) / 360;
                distanceToCenterNormalized = 1-(distanceToCenterNormalized*2);
                if(centerHotspot)
                    distanceToCenterNormalized = distanceToCenterNormalized / 2;
                let lineHeight = distanceToCenterNormalized*(maxHeight);

                if(centerHotspot)
                    lineHeight = lineHeight / 2;

                if (lineHeight < baseHeight) {lineHeight = baseHeight}
                // NOTE: Determine opacity (if lower than 0 on z axis, make transparent up to 50% at -36000... ish )
                let opacity = 1.0;
                if (vectorWorldPos.z < 0 && !centerHotspot)
                {
                    opacity = 1 + (vectorWorldPos.z / 36000) * 0.55; // TODO: No magic numbers
                }
                
                this.hotspot2DPositions[i] = {x: vector.x, y: vector.y, opacity: opacity, lineHeight: lineHeight};
            


            });
        },
        updateLoop()
        {
            if(!this.clearUpdateLoop){
                if (this.enabled)
                {
                    // NOTE(Mølby): Make sure globe is rotated
                    if(parentTransform)
                        parentTransform.rotation.y = Math.degToRad(this.rotation);
                    
                    if(renderer && scene && camera)
                        renderer.render(scene, camera);

                    this.calcHotspot2DPositions();
                }

                requestAnimationFrame(this.updateLoop);
            }
        },
        getUmbracoMediaItemSrc(mediaItem)
        {
            return getUmbracoMediaItemSrc(mediaItem);
        }
    }
}
</script>